#icon-id{
    font-size: 25px;
    cursor: pointer;
    color: #010e31;
}

/*********** Pagnation ************/
.bnw-table-pgnate-contain{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* background-color: aqua; */
    margin-top: 10px;
}

.forgotpass-cont{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 90%;
}

.forgotpass-cont > span{
    font-size: 14px;
    color: blue;
    cursor: pointer;
    font-weight: 500;
}

.forgotpass-cont > span:hover{
    color: #e50000;
    text-decoration: underline;
}

#addbitn{
    color: white;
    background-color: #e50000;
    padding: 8px 15px;
    margin-right: 5px;
    border: none;
    outline: none;
    font-weight: 500;
    cursor: pointer;
}

#addbitn:hover{
    background-color: #e500009c;
}

/********** Login Page ********/

.signinContain{
    width: 100%;
    /* height: calc(100vh - 100px); */
    height: 100vh;
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.bnw-login-contain{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 560px;
    height: 100%;
}

.bnw-login-body{
    width: 350px;
    height: 450px;
    background-color: white;
    box-shadow: rgb(41 41 41 / 36%) 0px 3px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.bnw-login-body > span:nth-child(2){
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.resetpass-bnw{
    width: 90%;
    margin-bottom: 15px;
}

.resetpass-bnw > span{
    font-size: 15px;
}

.bnw-ligin-email-container{
    width: 90%;
    height: fit-content;
    position: relative;
    display: flex;
}

.bnw-ligin-email, .bnw-ligin-password{
    width: 90%;
    padding: 8px 10px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    outline: none;
    font-weight: 500;
    margin-bottom: 20px;
}

.bnw-ligin-password{
    width: 100%;
}

.bnw-login-submit{
    background-color: #e50000;
    outline: none;
    border: 1px solid #e50000;
    color: white;
    width: 90%;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
}

.bnw-lock-icon-container{
    background-color: #e50000;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 30px;
    margin-top: 30px;
}

#errorInfo{
    margin-top: 20px;
}

#bnw-lock-icon{
    font-size: 20px;
    color: white;
}

#visib-icon{
    font-size: 18px;
    position: absolute;
    top: 20%;
    right: 3%;
    color: #888;
    cursor: pointer;
}

/*********Table Filter Container**********/
.bnw-filter-tab-cntainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    width: fit-content;
}

.bnw-fcc{
    color: white;
    padding: 8px 10px;
    min-width: 50px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
}

.inactive{
    background-color: rgba(136, 136, 136, 0.884);
}

.inactive:hover{
    background-color: rgba(110, 110, 110, 0.884);
}

.tab3{
    background-color: #ffac0580;
}

.tab3:hover{
    background-color: #ffac05c2;
}

/********** Dashboard Page ********/
.bnwd-container{
    width: 100%;
    min-width: 560px;
    min-height: 100vh;
    background-color: #F7F8FA;
    display: flex;
    flex-direction: column;
}

.bnw-botm-container{
    width: 100%;
    /* background-color: aqua; */
    display: flex;
    position: relative;
}


/****************Body Container****************/

.bnw-body-container{
    width: 100%;
    min-height: 100vh;
    /* background-color: brown; */
    display: flex;
    /* overflow-y: auto; */
    padding-bottom: 20px;
}

.bnw-body-left{
    width: 72%;
    min-height: 100vh;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-right: 12px;
}

.bnw-body-left-top{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: center; */
    justify-content: space-between;
    width: 100%;
    min-height: 200px;
    /* background-color: green; */
}

.bnw-body-right{
    width: 25%;
    min-height: 100vh;
    /* background-color: rgb(217, 255, 0); */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.bnw-body-left-mid{
    display: flex;
    width: 100%;
    min-height: 400px;
    flex-wrap: wrap;
    flex-grow: 1;
    /* background-color: green; */
}

/***************Claim Container***************/

.bnw-claim-container{
    width: 100%;
    min-width: 560px;
    min-height: 100vh;
    /* background-color: rgb(122, 165, 42); */
    padding: 20px;
    position: relative;
}

.bnw-claims-table-container{
    display: flex;
    flex-direction: column;
}

.bnw-claims-table{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.089);
    height: 80vh;
    /* background-color: aqua; */
}

#bnw-claims-table-head{
    width: 100%;
    padding: 5px;
    background-color: #e50000;
}

#bnw-claims-table-head > tr > td{
    padding: 8px;
    /* font-size: 16px; */
    font-weight: 700;
    color: white;
}

td{
    padding: 5px;
    font-weight: 500;
    text-align: center;
    border: 0.5px solid rgba(0, 0, 0, 0.089);
    border-collapse: collapse;
}

#bnw-tab-status, #bnw-tab-status_1, #bnw-tab-status_2{
    padding: 5px 3px;
    border-radius: 10px;
    background-color: rgba(5, 199, 5, 0.623);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

#bnw-status-deact{
    padding: 5px 3px;
    width: 120px;
    border-radius: 10px;
    background-color: #ffac0580;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

#bnw-status-act{
    padding: 5px 3px;
    width: 120px;
    border-radius: 10px;
    background-color: rgba(5, 199, 5, 0.623);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

#status-col{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* background-color: purple; */
}

#bnw-tab-status_1{
    background-color: #ffac0580;
}

#bnw-tab-status_2{
    background-color: #ff2b0580;
}

/***************Director Container***************/

.bnw-director-container{
    width: 100%;
    min-height: 100vh;
    /* background-color: rgb(42, 165, 108); */
    position: relative;
}

/***************Agency Container***************/

.bnw-agency-container{
    width: 100%;
    min-height: 100vh;
    /* background-color: rgb(42, 165, 108); */
}

.bnw-agency-container_{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;   
}

.bnw-agency-container_ > span{
    font-size: 18px;
    font-weight: 600;
}

/***************Settings Container***************/

.bnw-settings-container{
    width: 100%;
    min-height: 100vh;
    background-color: rgb(42, 81, 165);
}

.bnw-settings-container_{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

/***************Dashboard Container***************/
.bnw-body-left-botm{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    /* background-color: brown; */
    padding-right: 12px;
}

@media screen and (max-width: 750px) {
    .bnw-body-container{
        /* background-color: brown; */
        flex-direction: column;
        /* overflow: auto; */
        padding-bottom: 20px;
    }

    .bnw-body-left{
        width: 100%;
        min-height: 100vh;
        /* background-color: aqua; */
        margin-bottom: 15px;
    }

    .bnw-body-right{
        width: 100%;
        min-height: fit-content;
        /* background-color: rgb(217, 255, 0); */
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 12px;
    }
}