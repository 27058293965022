*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.payment-butn{
    padding: 8px 12px;
    color: white;
    background-color: #e50000;
    outline: none;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid transparent;
    /* margin-top: 5px; */
}


.payment-butn:hover{
    border: 1px solid #e50000;
    background-color: white;
    color: #e50000;
}








.bnw-logout{
    text-decoration: none;
    color: white;
}

.bnw-logout:hover{
    text-decoration: none;
    color: #e50000;
}

#bnw-logout-button{
    font-size: 15px;
    padding: 5px 15px;
    font-weight: 600;
    color: white;
    background-color: #e50000;
    outline: none;
    border: 1px solid #e50000;
    margin-top: 10px;
    cursor: pointer;
}

#bnw-logout-button:hover{
    background-color: white;
    color: #e50000;
}

.pswdfield{
    position: relative;
}

#visbIcon{
    position: absolute;
    right: 0;
    top: 25px;
    color: #888;
    align-items: center;
    font-size: 30px;
    margin-right: 10px;
}

.mainContain, .adminContainer, .mainContain_{
    width: 100%;
    min-height: 100vh;
    background-color: #eeeeee;
    position: relative;
    padding: 20px;
    min-width: 450px;
}

.adminContainer{
    min-height: calc(100vh - 55px);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.iconContainer, .iconContainer2{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

#certificateIcon{
    color: #e50000;
    width: 100px;
    height: 100px;
    min-width: 60px;
    min-height: 60px;
}

#errorIcon{
    margin-top: 40px;
    width: 80px;
    height: 80px;
    min-width: 60px;
    min-height: 60px;
}

#inputField{
    padding: 10px;
    min-width: 300px;
    width: 60%;
    border: 1px solid #8888;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    color: black;
}

::placeholder{
    color: #8888;
}

#heading{
    font-size: 29px;
    font-weight: 600;
    text-align: center;
}

.bodyContiner{
    /* background-color: #e50000; */
    height: calc(100vh - 105px);
}

#submitForm{
    margin: auto;
    max-width: 70%;
    min-width: 350px;
    /* min-height: calc(60vh - 50px); */
    margin-top: 20px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: blue; */
    /* justify-content: center; */
}

.errorContainer{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.514);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    top: 0
}

#closeIcon{
    font-size: 30px;
    color: rgba(0, 0, 0, 0.747);
    cursor: pointer;
}

#confIcon, #gradIcon, #nongradIcon, #paidIcon{
    width: 50px;
    height: 50px;
    color: green;
}

#gradIcon{
    color: rgb(19, 0, 128);
}

#nongradIcon{
    color: purple;
}

#errorIcon{
    width: 80px;
    height: 80px;
    color: #e50000;
    /* background-color: burlywood; */
    margin: 0;
}

.errorSubContain{
    background-color: white;
    width: 350px;
    height: 43vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgb(41 41 41 / 36%) 0px 3px 6px;
}

.paySubContain{
    min-width: 300px;
    width: 400px;
    min-height: 80vh;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.payContainer{
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#paybutton{
    padding: 8px;
    cursor: pointer;
    border: 1px solid green;
    font-size: 15px;
    background-color: green;
    color: white;
}

#paybutton:hover{
    color: green;
    background-color: white;
}

.ClsiconContain{
    width: 100%;
    display: flex;
    justify-content:flex-end;
    height: 30px;
    cursor: pointer;
    padding: 10px 10px 0px 0px;
}

#closeIcon{
    color: #e50000;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

#closeIcon:hover{
    color: #ff0000b6;
}

#errorInfo{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 0px 10px 10px 10px;
}

.ClsiconContain{
    width: 90%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.mainfootContain{
    position: relative;
    background-color: blue;
}

.footerContainer, .footerContainerSup{
    /* position: absolute;
    bottom: 0px; */
    width: 100%;
    height: 50px;
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;  
    padding: 0px 5px;
}

.footerContainerSup{
    background-color: #ffffff;
}

#footer-text{
    font-size: 14px;
    text-align: center;
}

#submitForm_{
    margin: auto;
    min-width: 340px;
    width: 40%;
    min-height: 450px;
    height: 85vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 35px 15px 25px 15px;
}

@media screen and (max-width : 450px) {
    #submitForm_{
        background-color: #f8f1f1e8;
    }
}

.sub-submitForm_{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.buttnContainer{
    min-width: 300px;
    width: 60%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#submitContainer{
    display: flex;
    position: relative;
    min-width: 140px;
    width: 48%;
}

#submitIcon, #submitIcon2{
    position: absolute;
    top: 50%;
    right: 20%;
    font-size: 22px;
    color: white;  
}

#submitIcon2{
    right: 15%;
    color: #e50000;
}

.sub-submitForm_{
    width: 70%;
    min-width: 200px;
}

#submitButn{
    color: white;
    padding: 10px;
    width: 100%;
    font-size: 16px;
    border: 1px solid #e50000;
    outline: none;
    margin-top: 20px;
    cursor: pointer;
    background-color: #e50000;
    text-align: center;
    font-weight: 600;
  }

  #submitButn_:disabled, #submitButn_[disabled] {
    background-color: #767676;
    color: #ffffff;
    padding: 10px;
    width: 100%;
    font-size: 16px;
    border: 1px solid #767676;
    outline: none;
    margin-top: 20px;
    font-weight: 600;
}

#submitButn_{
    font-weight: 600;
    background-color: #ffffff;
    color: green;
    padding: 10px, 18px;
    width: 100%;
    font-size: 16px;
    border: 1px solid green;
    outline: none;
    text-align: center;
    cursor: pointer;
    margin-right: 5px;
}

#urlLink{
    color: blue;
    cursor: pointer;
    font-weight: 400;
}

#urlLink:hover{
    color: #e50000;
    text-decoration: underline;
}

.filename{
    text-align: center;
}

.paymentConfPg{
    width: 100%;
    height: 100vh;
    min-width: 400px;
    background-color: rgba(0, 0, 0, 0.596);
    position: absolute;
}

.navbarContian{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: rgb(41 41 41 / 6%) 0px 2px 4px;
}

.navbar-icon{
    width: 130px;
    height: 100%;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
}

#imgIcon{
    background: url('../assets/sabre.png') no-repeat;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover; 
    height: 100%;
    width: 100% ;
}

.signinContain{
    width: 100%;
    height: calc(100vh - 55px) ;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainm-contain{
    position: relative;
}

#loginclick{
    cursor: pointer;
    background-color: #ffffff;
    height: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
    font-weight: 600;
    font-size: 18px;
}

#loginclick:hover{
    box-shadow: rgb(41 41 41 / 6%) 0px 3px 6px;
}

#loginclick_{
    display: none;
    font-size: 20px;
}

@media screen and (max-width:1100px) {
    #submitIcon, #submitIcon2{
        display: none;  
    }
}

@media screen and (max-width: 600px) {
    #heading{
        font-size: 22px
    }
}

@media screen and (max-width: 500px) {
    #loginclick{
        display: none;
    }

    #loginclick_{
        display: block;
        font-size: 20px;
        margin-right: 20px;
    }

    .navbarContian{
        height: 45px;
    }
    
    #visbIcon{
        font-size: 20px;
    }

    .footerContainer, .footerContainerSup{
        display: none;
    }
}