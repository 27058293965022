.pointcard-container{
    min-width: 240px;
    width: 25%;
    max-width: 400px;
    height: 220px;
    border-radius: 10px;
    flex-grow: 1;
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
}

.circle1{
    width: 43%;
    height: 75%;
    background-color: rgba(255, 255, 255, 0.151);
    position: absolute;
    top: 10%;
    left: 20%;
    border-radius: 50%;
}

.circle2{
    width: 43%;
    height: 75%;
    background-color: rgba(255, 255, 255, 0.226);
    position: absolute;
    bottom: -10%;
    left: -15%;
    border-radius: 50%;
}

.circle3{
    width: 43%;
    height: 75%;
    background-color: rgba(255, 255, 255, 0.226);
    position: absolute;
    top: -17%;
    left: 55%;
    border-radius: 50%;
}

.pcrd-row, .pcrd-row1, .pcrd-row2{
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
    padding: 5px 0px;
}

.pcrd-row{
    margin-bottom: 20px;
}

.pcrd-row1 > span{
    font-size: 36px;
    font-weight: 700;
}

.pcrd-row2{
    justify-content: center;
    margin-bottom: 10px;
    /* background-color: green; */
}

.pcrd-row2 > span{
    font-size: 20px;
    font-weight: 600;
}

#pcrd-icons{
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 400px) {
    .circle1, .circle2{
        width: 53%;
        height: 75%;
    }
}