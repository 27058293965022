.class-row1{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px 20px;
    justify-content: space-around;
    /* background-color: brown; */
    margin-bottom: 10px;
}

.class-row2{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
}

.topbar-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
    margin-bottom: 10px;
}

.firstdiv{
    display: flex;
    align-items: center;
}

.firstdiv > span{
    font-size: 16px;
    font-weight: 600;
}

.top-div-container{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.ecert-div-container{
    display: flex;
    align-items: center;
}

.seconddiv{
    font-size: 16px;
    font-weight: 600;
    background-color: green;
    cursor: pointer;
    padding: 10px 18px;
    color: white;
    border-radius: 10px;
    border: 1px solid white;
}

#home-icon{
    font-size: 40px;
    background-color: purple;
    padding: 8px;
    color: white;
    border-radius: 5px;
    margin-right: 15px;
}

.ecert-table-container{
    display: flex;
    flex-direction: column;
    width: 100%;
}


table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  
  table tr {
    border: 1px solid #ddd;
  }
  
  table th,
  table td {
    padding: .625em;
    font-size: 14px;
    word-wrap: break-word;
  }

  thead > tr{
    /* background-color: #194e91; */
    background-color: #e50000;
  }

  tr > th{
    color: white;
    font-size: 16px;
  }

  tbody > tr:nth-child(even) {
    background-color: #194d9113;
    min-height: 60px;
  }
  
  @media screen and (max-width: 700px) {
    table {
      border: 0;
    }
    
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 13px;
      text-align: right;
    }
    
    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    table td:last-child {
      border-bottom: 0;
    }
  }