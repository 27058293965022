.loadercontainer{
    width: calc(100% - 250px);
    height: 100vh;
    position: absolute;
    top: 0;
    background-color: white;
    display:flex;
    justify-content: center;
    align-items:center;
}

.loadingBox{
    border: 1px solid grey;
    width: 8%;
    display: flex;
    justify-content:center;
    align-items:center;
    /* height: 15%; */
}

.bnw-loadercontainer{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.822);
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
}

.bnw-loadingBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 15%;
}

.bnw-loadingBox > span{
    font-size: 16px;
    font-weight: 600;
    /* color: #e50000; */
    color: white;
    text-align: center;
}