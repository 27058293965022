.bnw-addirector-pg{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
}

.bnw-region-drpdwn{
    width: 100%;
    padding: 0px 0px 10px 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 0.1px solid rgba(0, 0, 0, 0.281);
    max-height: 150px;
    position: absolute;
    z-index: 100;
    top: 35px;
    overflow-y: scroll;
}

.bnw-region-drpdwn > span{
    width: 100%;
    padding: 5px 10px;
    cursor: pointer;
}

.bnw-region-drpdwn > span:hover{
    background-color: rgba(0, 0, 0, 0.192);
}

.main-add-dir-container{
    position: relative;
}

.bnw-addirector-contain{
    width: 40%;
    min-width: 350px;
    min-height: 65vh;
    max-height: 75vh;
    background-color: white;
    padding: 20px;
}

.bnw-pswrd-blk{
    display: flex;
    flex-direction: column;
}

.bnw-regionfield{
    padding: 8px 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.178);
    width: 100%;
    flex-grow: 1;
}

.bnw-dirname-container{
    /* background-color: aqua; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
    position: relative;
}

#bnw-firname-box{
    padding: 8px 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.178);
    width: 200px;
    flex-grow: 1;
    font-weight: 500;
}

#bnw-firname-box_{
    padding: 8px 10px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.178);
    flex-grow: 1;
    /* background-color: green; */
    width: 100%;
}

.bnw-direct-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 10px;
    position: relative;
}

#bnw-close-icon{
    font-size: 30px;
    color: rgba(51, 51, 51, 0.842);
    cursor: pointer;
    position: absolute;
    right: 0px;
}

.bnw-direct-heading > span{
    font-size: 18px;
    font-weight: 600;
}

#bnw-submit-butn{
    width: 100%;
    padding: 10px;
    background-color: white;
    margin-top: 30px;
    border: 1px solid #e50000;
    background-color: #e50000;
    color: white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}

#bnw-submit-butn:hover{
    background-color: #e50000ab;
}

@media screen and (max-width: 1050px) {

    #bnw-firname-box:last-child{
        margin-top: 10px;
    }
}